import CASL from '@/utils/constants/casl'

export default {
  path: 'customer',
  children: [
    {
      path: '',
      name: 'customer.list',
      meta: {
        action: 'read',
        subject: CASL.ACCESS.CUSTOMER
      },
      component: () => import('./list/CutomerListPage.vue')
    },
    {
      path: ':uid',
      name: 'customer.detail',
      meta: {
        action: 'read', // TODO: Should be 'update'?
        subject: CASL.ACCESS.CUSTOMER
      },
      component: () => import('./form/CustomerFormPage.vue')
    },
    {
      path: 'add/fleet',
      name: 'fleet.add',
      meta: {
        action: 'create',
        subject: CASL.ACCESS.CUSTOMER
      },
      component: () => import('./form-fleet/FleetFormPage.vue')
    }
  ]
}
